import { TTextBlock } from "@page-builder/ts/generated";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { replacePlaceholders } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";
import CloseIcon from "@components/svg/closeIcon";
import dynamic from "next/dynamic";
const Modal = dynamic(() => import("@components/shared/modal"));

export default function TextBlock({
    props = {},
}: {
    props: TTextBlock["props"];
}) {
    const [showModal, setShowPartnerPopup] = useState(false);
    const { text, modalContent, modalTriggerId, modalTitle } = props;
    const { category, visitDetails } = useDomainContext();

    useEffect(() => {
        if (modalTriggerId && modalContent) {
            const modalTrigger = document.getElementById(modalTriggerId);
            modalTrigger?.addEventListener("click", () => {
                setShowPartnerPopup(true);
            });
            modalTrigger?.classList.add(styles["modal-trigger"]);
        }
    }, [modalTriggerId]);

    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: text
                        ? replacePlaceholders(text, {
                              category: category?.name,
                              region: visitDetails.region,
                              city: visitDetails?.city,
                          })
                        : "",
                }}
                className={`${styles["text-block"]} prose md:prose-md max-w-none`}
            />
            {modalTriggerId && modalContent && (
                <Modal
                    onClose={() => {
                        setShowPartnerPopup(false);
                    }}
                    active={showModal && !!modalContent}
                    closeIcon={<CloseIcon />}
                    backdropClosable
                    modalStyles={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "100%",
                    }}
                    modalClassName="h-[90vh] rounded"
                    bodyStyles={{ overflowY: "scroll" }}
                    width={900}
                    header={modalTitle}
                >
                    <div
                        className="pb-5 lg:px-9 px-5"
                        dangerouslySetInnerHTML={{
                            __html: modalContent,
                        }}
                    />
                </Modal>
            )}
        </>
    );
}
